<style scoped>
    .main-content-container{
        overflow: auto;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .loader{
        display: flex;
        width: 100%;
        height: 100vh;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .login-container {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #F29727;
    }
    .login-container, .loader {
        height: 100vh;
    }
    .logo {
        background-color: white;
        border-radius: 4px;
        padding: 20px;
        margin: 20px 5px 40px 5px;
    }
    .logo img {
        max-width: 100%;
        height: auto;
        box-sizing: border-box;
    }

    .login-box {
        background-color: #fff;
        padding: 20px;
        border-radius: 4px;
        margin: 5px;
    }

    form {
        display: flex;
        flex-direction: column;
        margin-top: 60px;
    }

    label {
        font-size: 16px;
        margin-bottom: 6px;
        display: block;
    }

    input {
        padding: 10px;
        margin-bottom: 12px;
        font-size: 14px;
        border: 1px solid #ccc;
        border-radius: 4px;
        display: block;
        width: 100%;
        box-sizing: border-box;
    }

    button {
        background-color: #2895D2;
        color: #fff;
        padding: 12px;
        font-size: 16px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        margin-top: 40px;
        transition: all 300ms;
    }

    button:hover {
        background-color: #E33222;
    }

    .error-box{
        width: 100%;
        padding: 20px;
        border-radius: 7px;
        background-color: #b91c1c;
        color: #fff;
        text-align: center;
        box-sizing: border-box;
        font-size: 20px;
    }
    @media screen and (max-width: 767px) {
        .login-container, .loader {
            height: auto;
        }
    }
</style>

<template>
    <div class="main-content-container">
        <div v-if="loading" class="loader">
            <img src="/images/loader.svg" alt="Loader" />
        </div>
        <div v-if="!loading" class="login-container">
            <div class="logo">
                <img src="images/logofamdif.png" alt="">
            </div>
            <div class="login-box">
                <h1>{{ title }}</h1>
                <div class="error-box" v-if="data.error.show">
                    <span class="error">{{ data.error.message }}</span>
                </div>
                <form @submit.prevent="loginUser">
                    <div>
                        <label for="email">Correo Electrónico</label>
                        <input type="email" id="email" v-model="data.formData.email" required>
                    </div>
                    <div>
                        <label for="password">Contraseña</label>
                        <input type="password" id="password" v-model="data.formData.password" required>
                    </div>
                    <button type="submit">Iniciar Sesión</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { reactive, ref } from 'vue'
    import axios from 'axios'
    import store from '../store'
    import router from '../routes.js'
    import AlertComponent from '../components/AlertsComponent.vue'

    let loading = ref(false)

    localStorage.removeItem('famdifAdmin')

    let title = 'Acceso al Panel de Administración'
    const data = reactive({
        formData: {
            email: '',
            password: '',
        },
        error: {
            show: false,
            message: '',
        },
    });


    const loginUser = async () => {
        loading.value = true

        try {
            const response = await axios.post(store.state.baseApi + 'auth-admin', data.formData);

            if (response.data.error) {
                data.error.show = true
                data.error.message = response.data.error
                loading.value = false
            } else {
                localStorage.setItem('famdifAdmin', response.data.token)
                router.push('/panel/inicio')

            }
        } catch (error) {
            loading.value = false
            data.error.show = true
            data.error.message = error.message
            console.error('Error al iniciar sesión', error);
        }
    };

</script>

