<style scoped>
.main-content-container{
    overflow: auto;
    max-width: 100%;
}
.loader {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
#map {
    height: 400px;
    width: 100%;
    margin-bottom: 25px;
}
.shop-list {
    display: flex;
    flex-direction: column;
    background-color: white;
    max-width: 100%;
    margin: 50px;
}

.go-back {
    width: fit-content;
    font-size: 14px;
    text-transform: uppercase;
    background-color: #E33222;
    margin: 30px 0px;
    transition: all 300ms;
}

.go-back:hover {
    background-color: #9b2419;
}

.shop-images {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    margin-bottom: 40px;
}

.image-container {
    position: relative;
    overflow: hidden;
    max-width: 100%;
}

img {
    width: 300px;
    height: 300px;
    object-fit: contain;
}

.delete-button {
    position: absolute;
    top: 15px;
    right: 10px;
    background-color: red;
    color: white;
    border: none;
    padding: 2px 8px 3px 9px;
    border-radius: 100%;
    cursor: pointer;
}

.add-image-button {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 40px 0px;
}

.shop-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.shop-card {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 4px;
    grid-column: span 1;
}

/* Estilos para el formulario */
.shop-form {
    width: 100%;
    margin: 0 auto;
}

/* Estilos para los grupos de formulario (label encima del input) */
.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.form-group label {
    font-weight: bold;
}

.form-group input, .form-group select  {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Estilos para el botón */
.button-container {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 50px 0px;
}

button {
    background-color: #2895D2;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 20px;
    transition: all 300ms;
}

button:hover {
    background-color: #E33222;
}

textarea {
    resize: vertical;
    padding: 10px;
    font-family: 'Montserrat', sans-serif;
    border: 1px solid #ccc;
    border-radius: 4px;

}
@media screen and (max-width: 780px) {
    .shop-list{
        margin: 5px;
    }
    .shop-grid {
        display: flex;
        flex-direction: column;
    }
}
@media screen and (max-width: 768px) {
    .main-content-container{
    margin-top: 80px;
}
}
</style>

<template>
    <div class="main-content-container">
        <div v-if="loading" class="loader">
            <img src="/images/loader.svg" alt="Loader" />
        </div>
        <alert-component :show="showAlert" :message="alertMessage" @cerrar="cerrarAlerta" />
        <div v-if="!loading" class="shop-list">
            <h1>{{ title }} {{ shop.name }}</h1>
            <button v-on:click="goBack()" class="go-back">Volver al listado</button>
            <form @submit="addShop" class="shop-form">

                <div class="shop-grid">
                    <div class="shop-card">
                        <div class="form-group">
                            <label for="file_number">Nº de Ficha</label>
                            <input type="text" id="file_number" v-model="shop.file_number" />
                        </div>
                        <div class="form-group">
                            <label for="external_sorting">Clasificación Ext.</label>
                            <input type="text" id="external_sorting" v-model="shop.external_sorting" />
                        </div>
                    </div>
                    <div class="shop-card">
                        <div class="form-group">
                            <label for="long">Latitud</label>
                            <input type="text" id="long" v-model="shop.lat" />
                        </div>
                        <div class="form-group">
                            <label for="lat">Longitud</label>
                            <input type="text" id="lat" v-model="shop.long" />
                        </div>
                    </div>
                    <div class="shop-card">
                        <div class="form-group">
                            <label for="name">Nombre Comercial</label>
                            <input type="text" id="name" v-model="shop.name" />
                        </div>
                        <div class="form-group">
                            <label for="activity">Uso</label>
                            <select v-model="shop.use">
                                <option v-for="use in uses.value" :value="use">{{ use }}</option>
                            </select>
                        </div>

                        <div class="form-group">
                            <label for="activity">Actividad</label>
                            <input type="text" id="activity" v-model="shop.activity" />
                        </div>
                    </div>
                    <div class="shop-card">
                        <div class="form-group">
                            <label for="address">Dirección</label>
                            <input type="text" id="address" v-model="shop.address" />
                        </div>
                        <div class="form-group">
                            <label for="long">Ciudad</label>
                            <input type="text" id="location" v-model="shop.location" />
                        </div>
                        <div class="form-group">
                            <label for="lat">Barrio</label>
                            <input type="text" id="district" v-model="shop.district" />
                        </div>
                    </div>
                    <div class="shop-card">
                        <div class="form-group">
                            <label for="address">Terraza</label>
                            <textarea rows="3" id="terrace" v-model="shop.terrace" />
                        </div>
                        <div class="form-group">
                            <label for="long">Interior</label>
                            <textarea rows="3" id="interior" v-model="shop.interior" />
                        </div>
                        <div class="form-group">
                            <label for="lat">Aseos</label>
                            <textarea rows="3" id="baths" v-model="shop.baths" />
                        </div>
                    </div>

                    <div class="shop-card">
                        <div class="form-group">
                            <label for="access">Acceso</label>
                            <textarea rows="3" id="access" v-model="shop.access" />
                        </div>
                        <div class="form-group">
                            <label for="access_door">Puerta de Acceso</label>
                            <textarea rows="3" id="access_door" v-model="shop.access_door" />
                        </div>
                    </div>
                </div>
                <div class="button-container">
                    <button type="submit">Guardar Cambios</button>
                </div>
            </form>
        </div>
    </div>
</template>


<script setup>
import axios from 'axios'
import { ref, toRefs, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import router from '../routes'
import AlertComponent from '../components/AlertsComponent.vue'

let loading = ref(false)
let showAlert = ref(false)
let alertMessage = ref('')

const route = useRoute();

const token = localStorage.getItem('famdifAdmin');
const headers = {
    Authorization: `Bearer ${token}`,
};

const title = 'Detalle del Comercio (las fotos se añaden tras crearlo)'
const shop = ref({
    file_number: '',
    name: '',
    use: '',
    location: '',
    district: '',
    activity: '',
    address: '',
    long: '',
    lat: '',
    access: '',
    access_door: '',
    external_sorting: '',
    terrace:'',
    interior: '',
    baths: ''
});

const uses = toRefs(null)

const goBack = () => {
    router.push('/panel/lista-comercios')
}

const showAlerts = (message) => {
    showAlert.value = true
    alertMessage.value = message
}

const cerrarAlerta = () => {
    showAlert.value = false;
}

const fetchUses = () => {
    loading.value = true
    axios.get('/api/admin/get-uses', {
        headers,
    })
        .then(res => {
            uses.value = res.data.uses
            loading.value = false
        })
        .catch(error => {
            loading.value = false
            console.error('Error al obtener la playa:', error);
        });
}

const addShop = () => {
    loading.value = true

    // Realizar la petición POST con formData
    axios.post('/api/admin/add-shop', shop.value, {
            headers: headers,
        })
        .then(response => {
            if(response.data.error){
                loading.value = false
                if(response.data.error.message){
                    showAlerts(response.data.error.message)
                } else {
                    showAlerts(response.data.error)
                }
            } else {
                router.push('/panel/detalle-comercio/' + response.data.id)
            }
            console.log('Playa añadida con éxito:', response.data);
        })
        .catch(error => {

            console.error('Error al actualizar playa:', error);
        });
}

fetchUses()

</script>
