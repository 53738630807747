<!-- AlertComponent.vue -->
<template>
    <div v-if="show" class="alert">
      <div class="alert-content">
        <div>{{ message }}</div>
        <button @click="cerrarAlerta">Cerrar</button>
      </div>
    </div>
  </template>

  <script>
  import { ref } from 'vue';

  export default {
    props: ['show', 'message'],
    methods: {
      cerrarAlerta() {
        this.$emit('cerrar');
      },
    },
  };
  </script>

  <style scoped>
  .alert {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5); /* Fondo semi-transparente para resaltar el mensaje */
    z-index: 999; /* Ajusta el valor según sea necesario */
  }

  .alert-content {
    max-width: 60%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    color: #333;
    padding: 50px;
    border-radius: 5px;
    text-align: center;
  }

  button {
    padding: 5px 10px;
    background-color: #F3A225 !important;
    border-radius: 7px;
    margin-top: 50px;
    background: none;
    border: none;
    color: #333;
    cursor: pointer;
  }
  </style>
