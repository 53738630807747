<style scoped>
.main-content-container{
    overflow: auto;
    max-width: 100%;
}
.loader {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
#map {
    height: 400px;
    width: 100%;
    margin-bottom: 25px;
}
.beach-list {
    display: flex;
    flex-direction: column;
    background-color: white;
    max-width: 100%;
    margin: 50px;
}

.go-back {
    width: fit-content;
    font-size: 14px;
    text-transform: uppercase;
    background-color: #E33222;
    margin-bottom: 20px;
    transition: all 300ms;
}

.go-back:hover {
    background-color: #9b2419;
}

.leaflet-container {
    width: 100%;
    height: 100%;
}

/* Styles for the map (adjust the height as needed) */
.leaflet-map {
    height: 400px;
}

.beach-images {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    margin-bottom: 40px;
}

.image-container {
    position: relative;
    overflow: hidden;
    max-width: 100%;
}

img {
    width: 300px;
    height: 300px;
    object-fit: contain;
}

.delete-button {
    position: absolute;
    top: 15px;
    right: 10px;
    background-color: red;
    color: white;
    border: none;
    padding: 0px 8px;
    border-radius: 7px;
    cursor: pointer;
}

.add-image-button {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 40px 0px;
}

.beach-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.beach-card {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 4px;
    grid-column: span 1;
}

/* Estilos para el formulario */
.beach-form {
    width: 100%;
    margin: 0 auto;
}

/* Estilos para los grupos de formulario (label encima del input) */
.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.form-group label {
    font-weight: bold;
}

.form-group input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Estilos para el botón */
.button-container {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 50px 0px;
}

button {
    background-color: #2895D2;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 20px;
    transition: all 300ms;
}

button:hover {
    background-color: #E33222;
}

textarea {
    resize: vertical;
    padding: 10px;
    font-family: 'Montserrat', sans-serif;

}
@media screen and (max-width: 1290px){
    .beach-images {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media screen and (max-width: 780px) {
    .beach-list{
        margin: 5px;
    }
    .beach-grid {
        display: flex;
        flex-direction: column;
    }
    .beach-images {
        grid-template-columns: repeat(1, 1fr);
    }
    img{
        width: 100%;
    }
}
@media screen and (max-width: 768px) {
    .main-content-container{
    margin-top: 80px;
}
}
</style>

<template>
    <div class="main-content-container">
        <div v-if="loading" class="loader">
            <img src="/images/loader.svg" alt="Loader" />
        </div>
        <div v-if="!loading" class="beach-list">
            <h1>{{ title }} {{ beach.value.name }}</h1>
            <button v-on:click="goBack()" class="go-back">Volver al listado</button>
            <div id="map"></div>
            <form @submit="updateBeach" class="beach-form">
                <div class="add-image-button">
                    <button @click.prevent="openFileSelector">Añadir Imagenes</button>
                    <input type="file" ref="fileInput" @change="handleFileChange" style="display: none" multiple>
                </div>
                <div class="beach-images">
                    <div v-for="img in beach.value.images" :key="img.id" class="image-container">
                        <img :src="img" alt="">
                        <button @click="removeImage(img)" class="delete-button">x</button>
                    </div>
                </div>

                <div class="beach-grid">
                    <div class="beach-card">
                        <div class="form-group">
                            <label for="name">Nombre de la Playa</label>
                            <input type="text" id="name" v-model="beach.value.name" />
                        </div>
                        <div class="form-group">
                            <label for="locality">Localidad</label>
                            <input type="text" id="locality" v-model="beach.value.locality" />
                        </div>
                    </div>
                    <div class="beach-card">
                        <div class="form-group">
                            <label for="long">Latitud</label>
                            <input type="text" id="long" v-model="beach.value.long" />
                        </div>
                        <div class="form-group">
                            <label for="lat">Longitud</label>
                            <input type="text" id="lat" v-model="beach.value.lat" />
                        </div>
                    </div>
                    <div class="beach-card">
                        <div class="form-group">
                            <label for="parking_pcd">Plazas de Estacionamiento Reservado</label>
                            <textarea rows="3" id="parking_pcd" v-model="beach.value.parking_pcd" />
                        </div>
                    </div>
                    <div class="beach-card">
                        <div class="form-group">
                            <label for="ramps">Rampas de Acceso a la Playa</label>
                            <textarea rows="3" id="ramps" v-model="beach.value.ramps" />
                        </div>
                    </div>
                    <div class="beach-card">
                        <div class="form-group">
                            <label for="runaways">Pasarelas Sobre la Arena</label>
                            <textarea rows="3" id="runaways" v-model="beach.value.runaways" />
                        </div>
                    </div>
                    <div class="beach-card">
                        <div class="form-group">
                            <label for="rollables">Tramos Enrollables</label>
                            <textarea rows="3" id="rollables" v-model="beach.value.rollables" />
                        </div>
                    </div>
                    <div class="beach-card">
                        <div class="form-group">
                            <label for="lockers">Vestuarios Adaptados</label>
                            <textarea rows="3" id="lockers" v-model="beach.value.lockers" />
                        </div>
                    </div>
                    <div class="beach-card">
                        <div class="form-group">
                            <label for="showers">Duchas</label>
                            <textarea rows="3" id="showers" v-model="beach.value.showers" />
                        </div>
                    </div>
                    <div class="beach-card">
                        <div class="form-group">
                            <label for="adapt_baths">Aseos Adaptados</label>
                            <textarea rows="3" id="adapt_baths" v-model="beach.value.adapt_baths" />
                        </div>
                    </div>
                    <div class="beach-card">
                        <div class="form-group">
                            <label for="adapt_shadows">Zonas de Sombra Adaptadas</label>
                            <textarea rows="3" id="adapt_shadows" v-model="beach.value.adapt_shadows" />
                        </div>
                    </div>
                    <div class="beach-card">
                        <div class="form-group">
                            <label for="adapt_swim">Zona de Baño Balizada</label>
                            <textarea rows="3" id="adapt_swim" v-model="beach.value.adapt_swim" />
                        </div>
                    </div>
                    <div class="beach-card">
                        <div class="form-group">
                            <label for="amp_chairs">Sillas Anfibias</label>
                            <textarea rows="3" id="amp_chairs" v-model="beach.value.amp_chairs" />
                        </div>
                    </div>
                    <div class="beach-card">
                        <div class="form-group">
                            <label for="amp_crutchs">Muletas Anfibias</label>
                            <textarea rows="3" id="amp_crutchs" v-model="beach.value.amp_crutchs" />
                        </div>
                    </div>
                    <div class="beach-card">
                        <div class="form-group">
                            <label for="swim_help">Modalidad de Ayuda al Baño</label>
                            <textarea rows="3" id="swim_help" v-model="beach.value.swim_help" />
                        </div>
                    </div>
                    <div class="beach-card">
                        <div class="form-group">
                            <label for="swim_help_schedule">Calendario y Horario de Ayuda al Baño</label>
                            <textarea rows="3" id="swim_help_schedule" v-model="beach.value.swim_help_schedule" />
                        </div>
                    </div>
                    <div class="beach-card">
                        <div class="form-group">
                            <label for="info_panel">Panel de Información</label>
                            <textarea rows="3" id="info_panel" v-model="beach.value.info_panel" />
                        </div>
                    </div>
                    <div class="beach-card">
                        <div class="form-group">
                            <label for="color">Color</label>
                            <textarea rows="3" id="color" v-model="beach.value.color" />
                        </div>
                    </div>
                    <div class="beach-card" style="grid-column: span 2 !important;">
                        <div class="form-group">
                            <label for="resume">Resumen</label>
                            <textarea rows="3" id="resume" v-model="beach.value.resume" />
                        </div>
                    </div>
                </div>
                <div class="button-container">
                    <button type="submit">Guardar Cambios</button>
                </div>
            </form>
        </div>
    </div>
</template>


<script setup>
import axios from 'axios'
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import router from '../routes'
import AlertComponent from '../components/AlertsComponent.vue'

let loading = ref(true)
let loadingMap = ref(true)

const route = useRoute();
const id = ref(route.params.id);

const fileInput = ref(null);

const token = localStorage.getItem('famdifAdmin');
const headers = {
    Authorization: `Bearer ${token}`,
};

const title = 'Detalle de la playa '
const beach = [];

const goBack = () => {
    router.push('/panel/lista-playas')
}

onMounted(() => {
    setTimeout(() => {
        showMap();
    }, 1500);
});

const fetchBeach = () => {
    loading.value = true
    axios.get('/api/admin/show-beach/' + id.value, {
        headers,
    })
        .then(res => {
            beach.value = res.data.beach;
            loading.value = false
        })
        .catch(error => {
            loading.value = false
            console.error('Error al obtener la playa:', error);
        });
}

const showMap = () => {
    const lat = beach.value.long.replace(',', '.')
    const long = beach.value.lat.replace(',', '.')
    var map = L.map('map').setView([lat, long], 17);
    var marker = L.marker([lat, long]).addTo(map);
    loadingMap.value = false
    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
    }).addTo(map).addTo(marker);
}

const removeImage = (image) => {
    loading.value = true

    const formData = new FormData();
    formData.append('id', id.value);
    formData.append('image', image);

    axios.post('/api/admin/delete-image', formData, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then(res => {
            beach.value = res.data.beach
            loading.value = false;
        })
        .catch(error => {
            loading.value = false;
            console.error('Error al guardar imágenes:', error);
        });
}

const openFileSelector = () => {
    // Abre el selector de archivos cuando se hace clic en el botón "Añadir Imagen"
    fileInput.value.click();
}

const handleFileChange = (event) => {
    // Maneja el cambio en el selector de archivos
    const files = event.target.files;
    // Implementa la lógica para procesar las nuevas imágenes
    console.log('Nuevas imágenes seleccionadas:', files);
    // Llama a la función saveNewImages() con las imágenes seleccionadas
    saveNewImages(files);
}

const saveNewImages = (files) => {
    loading.value = true

    const formData = new FormData();
    formData.append('id', id.value);
    for (let i = 0; i < files.length; i++) {
        formData.append(`images[${i}]`, files[i]);
    }

    axios.post('/api/admin/save-images', formData, {
        headers: {
            'Content-Type': 'multipart/form-data', // Necesario para enviar archivos
            Authorization: `Bearer ${token}`,
        },
    })
        .then(res => {
            beach.value = res.data.beach
            loading.value = false;
        })
        .catch(error => {
            loading.value = false;
            console.error('Error al guardar imágenes:', error);
        });
}

const updateBeach = () => {
    loading.value = true

    const formData = new FormData();

    for (const [key, value] of Object.entries(beach.value)) {
    // Excluir campos específicos
    if (key !== 'images' && key !== 'created_at' && key !== 'updated_at' && value !== null && value !== undefined) {
        formData.append(key, value);
    }
}

    // Realizar la petición POST con formData
    axios.post('/api/admin/edit-beach', formData, {
            headers: headers,
        })
        .then(response => {
            fetchUser()
            console.log('Playa actualizada con éxito:', response.data);
        })
        .catch(error => {
            loading.value = false
            // Manejar errores de la llamada al servidor
            console.error('Error al actualizar playa:', error);
        });

}

fetchBeach();

</script>
