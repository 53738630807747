<style scoped>
.main-content-container{
    overflow: auto;
    max-width: 100%;
}
.loader {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
#map {
    height: 400px;
    width: 100%;
    margin-bottom: 25px;
}
.beach-list {
    display: flex;
    flex-direction: column;
    background-color: white;
    max-width: 100%;
    margin: 50px;
}

.go-back {
    width: fit-content;
    font-size: 14px;
    text-transform: uppercase;
    background-color: #E33222;
    margin-bottom: 20px;
    transition: all 300ms;
}

.go-back:hover {
    background-color: #9b2419;
}

.leaflet-container {
    width: 100%;
    height: 100%;
}

/* Styles for the map (adjust the height as needed) */
.leaflet-map {
    height: 400px;
}

.beach-images {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    margin-bottom: 40px;
}

.image-container {
    position: relative;
    overflow: hidden;
    max-width: 100%;
}

img {
    width: 300px;
    height: 300px;
    object-fit: contain;
}

.delete-button {
    position: absolute;
    top: 15px;
    right: 10px;
    background-color: red;
    color: white;
    border: none;
    padding: 0px 8px;
    border-radius: 7px;
    cursor: pointer;
}

.add-image-button {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 40px 0px;
}

.beach-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.beach-card {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 4px;
    grid-column: span 1;
}

/* Estilos para el formulario */
.beach-form {
    width: 100%;
    margin: 0 auto;
}

/* Estilos para los grupos de formulario (label encima del input) */
.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.form-group label {
    font-weight: bold;
}

.form-group input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Estilos para el botón */
.button-container {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 50px 0px;
}

button {
    background-color: #2895D2;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 20px;
    transition: all 300ms;
}

textarea {
    resize: vertical;
    padding: 10px;
    font-family: 'Montserrat', sans-serif;

}

#color {
    padding: 10px; /* Relleno interno */
    font-size: 16px; /* Tamaño de fuente */
    color: #333; /* Color del texto */
    cursor: pointer; /* Cambiar el cursor al pasar sobre el desplegable */
  }

  /* Estilo de las opciones dentro del desplegable */
  #color option {
    font-family: 'Montserrat', sans-serif;
    padding: 10px 0px;
    background-color: #fff; /* Color de fondo de las opciones */
    font-size: 16px; /* Tamaño de fuente de las opciones */
    color: #333; /* Color del texto de las opciones */
  }

  /* Estilo del desplegable cuando está desplegado */
  #color:focus {
    outline: none; /* Quita el contorno al enfocar */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); /* Sombra cuando está enfocado */
  }
@media screen and (max-width: 1290px){
    .beach-images {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media screen and (max-width: 780px) {
    .beach-list{
        margin: 5px;
    }
    .beach-grid {
        display: flex;
        flex-direction: column;
    }
    .beach-images {
        grid-template-columns: repeat(1, 1fr);
    }
    img{
        width: 100%;
    }
}
@media screen and (max-width: 768px) {
    .main-content-container{
    margin-top: 80px;
}
}
</style>

<template>
    <div class="main-content-container">
        <div v-if="loading" class="loader">
            <img src="/images/loader.svg" alt="Loader" />
        </div>
        <alert-component :show="showAlert" :message="alertMessage" @cerrar="cerrarAlerta" />
        <div v-if="!loading" class="beach-list">
            <h1>{{ title }} {{ beach.name }}</h1>
            <button v-on:click="goBack()" class="go-back">Volver al listado</button>
            <form @submit="addBeach" class="beach-form">
                <div class="add-image-button">
                    <button style="cursor:not-allowed" disabled>Las imágenes se podrán añadir tras guardar los datos</button>
                </div>

                <div class="beach-grid">
                    <div class="beach-card">
                        <div class="form-group">
                            <label for="name">Nombre de la Playa</label>
                            <input type="text" id="name" v-model="beach.name" />
                        </div>
                        <div class="form-group">
                            <label for="locality">Localidad</label>
                            <input type="text" id="locality" v-model="beach.locality" />
                        </div>
                    </div>
                    <div class="beach-card">
                        <div class="form-group">
                            <label for="long">Latitud</label>
                            <input type="text" id="long" v-model="beach.long" />
                        </div>
                        <div class="form-group">
                            <label for="lat">Longitud</label>
                            <input type="text" id="lat" v-model="beach.lat" />
                        </div>
                    </div>
                    <div class="beach-card">
                        <div class="form-group">
                            <label for="parking_pcd">Plazas de Estacionamiento Reservado</label>
                            <textarea rows="3" id="parking_pcd" v-model="beach.parking_pcd" />
                        </div>
                    </div>
                    <div class="beach-card">
                        <div class="form-group">
                            <label for="ramps">Rampas de Acceso a la Playa</label>
                            <textarea rows="3" id="ramps" v-model="beach.ramps" />
                        </div>
                    </div>
                    <div class="beach-card">
                        <div class="form-group">
                            <label for="runaways">Pasarelas Sobre la Arena</label>
                            <textarea rows="3" id="runaways" v-model="beach.runaways" />
                        </div>
                    </div>
                    <div class="beach-card">
                        <div class="form-group">
                            <label for="rollables">Tramos Enrollables</label>
                            <textarea rows="3" id="rollables" v-model="beach.rollables" />
                        </div>
                    </div>
                    <div class="beach-card">
                        <div class="form-group">
                            <label for="lockers">Vestuarios Adaptados</label>
                            <textarea rows="3" id="lockers" v-model="beach.lockers" />
                        </div>
                    </div>
                    <div class="beach-card">
                        <div class="form-group">
                            <label for="showers">Duchas</label>
                            <textarea rows="3" id="showers" v-model="beach.showers" />
                        </div>
                    </div>
                    <div class="beach-card">
                        <div class="form-group">
                            <label for="adapt_baths">Aseos Adaptados</label>
                            <textarea rows="3" id="adapt_baths" v-model="beach.adapt_baths" />
                        </div>
                    </div>
                    <div class="beach-card">
                        <div class="form-group">
                            <label for="adapt_shadows">Zonas de Sombra Adaptadas</label>
                            <textarea rows="3" id="adapt_shadows" v-model="beach.adapt_shadows" />
                        </div>
                    </div>
                    <div class="beach-card">
                        <div class="form-group">
                            <label for="adapt_swim">Zona de Baño Balizada</label>
                            <textarea rows="3" id="adapt_swim" v-model="beach.adapt_swim" />
                        </div>
                    </div>
                    <div class="beach-card">
                        <div class="form-group">
                            <label for="amp_chairs">Sillas Anfibias</label>
                            <textarea rows="3" id="amp_chairs" v-model="beach.amp_chairs" />
                        </div>
                    </div>
                    <div class="beach-card">
                        <div class="form-group">
                            <label for="amp_crutchs">Muletas Anfibias</label>
                            <textarea rows="3" id="amp_crutchs" v-model="beach.amp_crutchs" />
                        </div>
                    </div>
                    <div class="beach-card">
                        <div class="form-group">
                            <label for="swim_help">Modalidad de Ayuda al Baño</label>
                            <textarea rows="3" id="swim_help" v-model="beach.swim_help" />
                        </div>
                    </div>
                    <div class="beach-card">
                        <div class="form-group">
                            <label for="swim_help_schedule">Calendario y Horario de Ayuda al Baño</label>
                            <textarea rows="3" id="swim_help_schedule" v-model="beach.swim_help_schedule" />
                        </div>
                    </div>
                    <div class="beach-card">
                        <div class="form-group">
                            <label for="info_panel">Panel de Información</label>
                            <textarea rows="3" id="info_panel" v-model="beach.info_panel" />
                        </div>
                    </div>
                    <div class="beach-card">
                        <div class="form-group">
                            <label for="color">Color</label>
                            <select name="color" id="color" v-model="beach.color">
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                            </select>
                        </div>
                    </div>
                    <div class="beach-card" style="grid-column: span 2 !important;">
                        <div class="form-group">
                            <label for="resume">Resumen</label>
                            <textarea rows="3" id="resume" v-model="beach.resume" />
                        </div>
                    </div>
                </div>
                <div class="button-container">
                    <button type="submit">Guardar Cambios</button>
                </div>
            </form>
        </div>
    </div>
</template>


<script setup>
import axios from 'axios'
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import router from '../routes'
import AlertComponent from '../components/AlertsComponent.vue'


let loading = ref(false)
let showAlert = ref(false)
let alertMessage = ref('')

const token = localStorage.getItem('famdifAdmin');
const headers = {
    Authorization: `Bearer ${token}`,
};

const title = 'Detalle de la playa '

const beach = ref({
    name: '',
    locality: '',
    long: '',
    lat: '',
    parking_pcd: '',
    ramps: '',
    runaways: '',
    rollables: '',
    lockers: '',
    showers: '',
    adapt_baths: '',
    adapt_shadows: '',
    adapt_swim: '',
    amp_chairs: '',
    amp_crutchs: '',
    swim_help: '',
    swim_help_schedule: '',
    info_panel: '',
    color: '',
    resume: '',
    // images: [],
});

const showAlerts = (message) => {
    showAlert.value = true
    alertMessage.value = message
}

const cerrarAlerta = () => {
    showAlert.value = false;
}

const goBack = () => {
    router.push('/panel/lista-playas')
}

const addBeach = () => {
    loading.value = true

    // Realizar la petición POST con formData
    axios.post('/api/admin/add-beach', beach.value, {
            headers: headers,
        })
        .then(response => {
            if(response.data.error){
                loading.value = false
                if(response.data.error.message){
                    showAlerts(response.data.error.message)
                } else {
                    showAlerts(response.data.error)
                }
            } else {
                router.push('/panel/detalle-playa/' + response.data.id)
            }
            console.log('Playa añadida con éxito:', response.data);
        })
        .catch(error => {

            console.error('Error al actualizar playa:', error);
        });

}

</script>
