<style scoped>
.main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    max-width: 100%;
    margin: 50px;
}
</style>

<template>
    <div class="main">
        <h1>{{ title }}</h1>
    </div>

</template>


<script setup>

    const title = 'Inicio';

</script>
