<style scoped>
.loader {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.user-list {
    display: flex;
    flex-direction: column;
    background-color: white;
    max-width: 100%;
    margin: 50px;
}

.go-back {
    width: fit-content;
    font-size: 14px;
    text-transform: uppercase;
    background-color: #E33222;
    margin: 30px 0px;
    transition: all 300ms;
}

.go-back:hover {
    background-color: #9b2419;
}

.leaflet-container {
    width: 100%;
    height: 100%;
}

/* Styles for the map (adjust the height as needed) */
.leaflet-map {
    height: 400px;
}

.delete-button {
    position: absolute;
    top: 15px;
    right: 10px;
    background-color: red;
    color: white;
    border: none;
    padding: 2px 8px 3px 9px;
    border-radius: 100%;
    cursor: pointer;
}

.user-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
}

.user-card {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 4px;
    grid-column: span 1;
}

/* Estilos para el formulario */
.user-form {
    width: 100%;
    margin: 0 auto;
}

/* Estilos para los grupos de formulario (label encima del input) */
.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.form-group label {
    font-weight: bold;
}

.form-group input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Estilos para el botón */
.button-container {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 50px 0px;
}

button {
    background-color: #2895D2;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 20px;
    transition: all 300ms;
}

button:hover {
    background-color: #E33222;
}

textarea {
    resize: vertical;
    padding: 10px;
    font-family: 'Montserrat', sans-serif;

}
@media screen and (max-width: 768px) {
    .main-content-container{
    margin-top: 80px;
}
}
</style>

<template>
    <div v-if="loading" class="loader">
        <img src="/images/loader.svg" alt="Loader" />
    </div>
    <div v-if="!loading" class="user-list">
        <h1>{{ title }} {{ user.value.first_name }}</h1>
        <button v-on:click="goBack()" class="go-back">Volver al listado</button>
        <form @submit.prevent="editUser" class="user-form">
            <div class="user-grid">
                <div class="user-card">
                    <div class="form-group">
                        <label for="first_name">Usuario</label>
                        <input type="text" id="first_name" v-model="user.value.first_name" />
                    </div>
                    <div class="form-group">
                        <label for="email">Email</label>
                        <input type="email" id="email" v-model="user.value.email" />
                    </div>
                    <div class="form-group">
                        <label for="zip_code">Código Postal</label>
                        <input type="text" id="zip_code" v-model="user.value.zip_code" />
                    </div>
                    <div class="form-group">
                        <label for="birthdate">Fecha de Nacimiento</label>
                        <input type="date" id="birthdate" v-model="user.value.birthdate"/>
                    </div>
                </div>
                <div class="user-card">
                    <div class="form-group">
                        <label for="isDisabled">¿Tiene discapacidad?</label>
                        <div style="margin-top: 20px">
                            <label>
                                <input v-model="userIsDisabled" type="radio" value="true" id="isDisabledYes" />
                                Sí
                            </label>
                            <label>
                                <input v-model="userIsDisabled" type="radio" value="false" id="isDisabledNo" />
                                No
                            </label>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="disability">Discapacidad</label>
                        <input type="text" v-model="user.value.disability" />
                    </div>
                </div>
            </div>
            <div class="button-container">
                <button type="submit">Guardar Cambios</button>
            </div>
        </form>
    </div>
</template>


<script setup>
import axios from 'axios'
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import router from '../routes'
import AlertComponent from '../components/AlertsComponent.vue'

let loading = ref(true)
const errorAlert = ref(null);

let userIsDisabled = ref(false)

const route = useRoute();
const id = ref(route.params.id);

const token = localStorage.getItem('famdifAdmin');
const headers = {
    Authorization: `Bearer ${token}`,
};

const title = 'Detalle del usuario '
const user = []

const goBack = () => {
    router.back()
}

const fetchUser = () => {
    // loading.value = true
    axios.get('/api/admin/show-user/' + id.value, {
        headers,
    })
        .then(res => {
            if(res.data.error){
                loading.value = false
                errorAlert.value = {
                    message: res.data.error.message,
                    type: 'error',
                };
                return
            } else {
                if (res.data.user) {
                    user.value = res.data.user;
                    user.value.birthdate = new Date(user.value.birthdate).toISOString().split('T')[0];
                    userIsDisabled.value = user.value.isDisabled;
                    loading.value = false;
                } else {
                    loading.value = false;
                    errorAlert.value = {
                        message: res.data.error.message,
                        type: 'error',
                    };
                    return
                }
            }
        })
        .catch(error => {
            loading.value = false
            errorAlert.value = {
                    message: 'Error al obtener la playa:', error,
                    type: 'error',
                };
            console.error('Error al obtener la playa:', error);
        })
}

const editUser = () => {
    loading.value = true

    const formData = new FormData()
    formData.append('id', user.value.id)
    formData.append('first_name', user.value.first_name)
    formData.append('email', user.value.email)
    formData.append('zip_code', user.value.zip_code)
    formData.append('birthdate', user.value.birthdate)
    formData.append('isDisabled', userIsDisabled.value)
    formData.append('disability', user.value.disability)

    // Realizar la petición POST con formData
    axios.post('/api/admin/edit-user', formData, {
            headers: headers,
        })
        .then(response => {
            fetchUser()
            console.log('Usuario añadido con éxito:', response.data);
        })
        .catch(error => {
            loading.value = false
            // Manejar errores de la llamada al servidor
            console.error('Error al añadir el administrador:', error);
        });

}

fetchUser();

</script>
