<!-- AlertComponent.vue -->
<template>
    <div v-if="show" class="alert">
        <div class="alert-content">
            <div>{{ message }}</div>
            <div class="buttons">
                <button class="accept" @click="acceptAlert">Aceptar</button>
                <button class="cancel" @click="cancelAlert">Cancelar</button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';

export default {
    props: ['show', 'message'],
    methods: {
        acceptAlert() {
            this.$emit('accept', true); // Emitir un evento llamado 'accept' con el valor true
        },
        cancelAlert() {
            this.$emit('cancel', false); // Emitir un evento llamado 'cancel' con el valor false
        },
    },
};
</script>

<style scoped>
.alert {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    /* Fondo semi-transparente para resaltar el mensaje */
    z-index: 999;
    /* Ajusta el valor según sea necesario */
}

.alert-content {
    max-width: 60%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    color: #333;
    padding: 50px;
    border-radius: 5px;
    text-align: center;
}

.buttons {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
}

.accept {
    padding: 5px 10px;
    background-color: #F3A225 !important;
    border-radius: 7px;
    margin-top: 50px;
    background: none;
    border: none;
    color: #333;
    cursor: pointer;
}

.cancel {
    padding: 5px 10px;
    background-color: #dc2626 !important;
    border-radius: 7px;
    margin-top: 50px;
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
}
</style>
