<style scoped>
.main-content-container {
    overflow: auto;
    max-width: 100%;
}

.loader {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.message-list {
    display: flex;
    flex-direction: column;
    background-color: white;
    max-width: 100%;
    margin: 50px;
}

.message-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
}

.message-card {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 4px;
    grid-column: span 1;
}

/* Estilos para el formulario */
.message-form {
    width: 100%;
    margin: 0 auto;
}

/* Estilos para los grupos de formulario (label encima del input) */
.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.form-group label {
    font-weight: bold;
}

.form-group input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Estilos para el botón */
.button-container {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 50px 0px;
}

button {
    background-color: #2895D2;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 20px;
    transition: all 300ms;
}

.vue-emoji-picker {
    position: absolute;
    z-index: 9999;
    /* Ajusta la posición y el estilo según tus necesidades */
  }

@media screen and (max-width: 780px) {
    .message-list {
        margin: 5px;
    }

    .message-grid {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 768px) {
    .main-content-container {
        margin-top: 80px;
    }
}
</style>

<template>
    <div class="main-content-container">
        <div v-if="loading" class="loader">
            <img src="/images/loader.svg" alt="Loader" />
        </div>
        <alert-component :show="showAlert" :message="alertMessage" @cerrar="cerrarAlerta" />
        <div v-if="!loading" class="message-list">
            <h1>{{ title }}</h1>
            <form @submit="sendMessage" class="message-form">
                <div class="message-grid">
                    <div class="message-card">
                        <div class="form-group">
                            <label for="name">Título</label>
                            <input type="text" id="title" v-model="message.title" />
                        </div>
                        <div class="form-group">
                            <label for="locality">Cuerpo del mensaje</label>
                            <input ref="bodyInput" type="text" id="body" v-model="messageVisual.value" />
                        </div>
                        <div class="form-group">
                            <!-- Utiliza el componente EmojiPicker -->
                            <EmojiPicker :native="true" @select="onSelectEmoji" />
                          </div>
                    </div>
                </div>
                <div class="button-container">
                    <button type="submit">Enviar notificación PUSH</button>
                </div>
            </form>
        </div>
    </div>
</template>


<script setup>
import axios from 'axios'
import { ref } from 'vue'
import EmojiPicker from 'vue3-emoji-picker'
import 'vue3-emoji-picker/css'
import AlertComponent from '../components/AlertsComponent.vue'

const bodyInput = ref(null);

let loading = ref(false)
let showAlert = ref(false)
let alertMessage = ref('')

const token = localStorage.getItem('famdifAdmin');
const headers = {
    Authorization: `Bearer ${token}`,
};

const title = 'Mandar Notificaciones PUSH a todos los dispositivos'

const message = ref({
    title: '',
    body: ''
});

const messageVisual = ref('')

const insert = (emoji) => {
    this.input += emoji
}

const showAlerts = (message) => {
    showAlert.value = true
    alertMessage.value = message
}

const cerrarAlerta = () => {
    showAlert.value = false;
}

const sendMessage = () => {
    loading.value = true
    message.value.body = bodyInput.value.value
    // Realizar la petición POST con formData
    axios.post('/api/admin/send-push-notification', message.value, {
        headers: headers,
    })
        .then(response => {
            if (response.data.error) {
                loading.value = false
                if (response.data.error.message) {
                    showAlerts(response.data.error.message)
                } else {
                    showAlerts(response.data.error)
                }
            } else {
                showAlerts('¡Notificación enviada!')
                message.value.title = ''
                message.value.body = ''
                loading.value = false
            }
            console.log('Notificación enviada con éxito:', response.data);
        })
        .catch(error => {

            console.error('Error al enviar notificación:', error);
        });
}

// event callback
function onSelectEmoji(emoji) {
    const escapedEmoji = emoji ? `\\u+{${emoji.r}}` : '';
    message.value.body = bodyInput.value.value + escapedEmoji;
    bodyInput.value.value += emoji.i;
    // bodyInput.value.value = messageVisual.value;
  /*
    // result
    {
        i: "😚",
        n: ["kissing face"],
        r: "1f61a", // with skin tone
        t: "neutral", // skin tone
        u: "1f61a" // without tone
    }
    */
}

</script>
