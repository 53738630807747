import { createStore } from 'vuex';

const isProduction = process.env.NODE_ENV === 'production';

const store = createStore({
    state: {
        baseApi: isProduction
            ? 'https://apifamdif.soporteunico.es/api/'
            : 'http://apifamdif.test/api/',
        baseUrl: isProduction
            ? 'https://apifamdif.soporteunico.es'
            : 'http://localhost:3000',
    },
    mutations: {
        updateGlobal(state, newValor) {
            state.baseApi = newValor;
        },
    },
});

export default store;
