<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;700&display=swap');

    :root {
        --main: #F29727;
        --blue: #2BA8D9;
        --red: #D92323;
        --dark-blue: #3A4E8C;
    }

    *{
        font-family: 'Montserrat', sans-serif;
    }
    .main-container{
        display: flex;
        flex-wrap: wrap; /* Permite que los elementos se envuelvan a la siguiente fila si no hay suficiente espacio */
        overflow: hidden;
    }
    .navbar-container{
        width: 15%
    }
    .content-container {
        width: 85%;
        overflow: auto;
    }
    @media screen and (max-width: 768px) {
        .navbar-container{
            width: 100%
        }
        .content-container {
            width: 100%;
            overflow: auto;
        }
    }
</style>

<template>
    <div class="main-container">
        <div class="navbar-container">
            <Navbar />
        </div>
        <div class="content-container ">
            <Content />
        </div>
    </div>
</template>

<script setup>
    import router from '../routes.js'

    const check = localStorage.getItem('famdifAdmin')
    if(!check){
        router.push('/login')
    }

</script>

