<style scoped>
.navbar {
    display: flex;
    flex-direction: column;
    background-color: #F3A225;
    padding: 0 10px;
    overflow-y: auto;
}

/* Estilos adicionales para el menú de hamburguesa */
.navbar-burger {
    display: none;
    /* Ocultar por defecto en pantallas más grandes */
    cursor: pointer;
    padding: 15px;
}

.bar {
    height: 3px;
    width: 25px;
    background-color: #202020;
    margin: 6px 0;
}

.burger-menu {
    display: none;
    /* Agrega estilos adicionales según tus necesidades */
}

.title {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    width: 100%;
    margin-bottom: 10px
}

.line {
    height: 1px;
    width: 100%;
    background: linear-gradient(to right, transparent, black, transparent);
    margin-bottom: 20px;
}

.logo {
    background-color: white;
    border-radius: 4px;
    padding: 20px;
    margin: 20px 0 40px 0;
    text-align: center;
}

.logo img {
    max-width: 100%;
    height: auto;
}

.link {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    transition: all 300ms;
    height: 100%;
    width: auto;
    /* O podrías probar con porcentajes, por ejemplo, width: 10%; */
    white-space: word-wrap;
    overflow: hidden;
    /* Ocultar cualquier desbordamiento */
    text-overflow: ellipsis;
    /* Agregar puntos suspensivos para indicar desbordamiento */
}

.link a {
    color: #202020;
    text-decoration: none;
    text-transform: uppercase;
    padding: 5px 0;
    transition: all 300ms;
}

.link .active {
    background-color: #fff;
    border-radius: 4px;
    font-weight: 700;
    color: #2895D2;
    padding: 5px 0;
    width: 100%;
    text-align: center;
    margin-bottom: 25px;
}

.link .nonactive {
    border: 2px solid rgba(255, 255, 255, 0.664);
    border-radius: 4px;
    padding: 5px;
    text-align: left;
    margin-bottom: 25px;
}

.logout {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: end;
}

.delete {
    width: 100%;
    cursor: pointer;
    background-color: #E33222;
    color: #fff;
    padding: 4px 8px;
    border: none;
    border-radius: 4px;
    transition: all 300ms;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 80px;
}


.delete:hover {
    background-color: #811e15;
}

@media screen and (max-width: 768px) {
    .navbar {
        display: none;
    }

    .navbar-burger {
        display: block;
        position: fixed;
        top: 25px;
        left: 25px;
        z-index: 9999;
        background-color: #fff;
        border: 1px solid rgb(187, 187, 187);
        height: fit-content;
        border-radius: 7%;
        padding: 10px 15px;
    }

    .burger-menu {
        /* Estilos para el menú hamburguesa en dispositivos móviles */
        display: flex;

        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #F3A225;
        z-index: 999;
        padding: 20px;
    }
}
</style>


<template>
    <div class="navbar">
        <div class="logo">
            <img src="/images/logofamdif.png" alt="">
        </div>
        <div class="title">GESTIÓN APP</div>
        <div class="line"></div>
        <div v-if="loading" class="loader">
            <img src="/images/loader.svg" alt="Loader" />
        </div>
        <div v-if="!loading" class="link">
            <router-link to="/panel/lista-administradores"
                :class="{ 'active': $route.path === '/panel/lista-administradores', 'nonactive': $route.path !== '/panel/lista-administradores' }">Lista
                de Admins</router-link>
            <router-link to="/panel/lista-usuarios"
                :class="{ 'active': $route.path === '/panel/lista-usuarios', 'nonactive': $route.path !== '/panel/lista-usuarios' }">Lista
                de Usuarios</router-link>
            <router-link to="/panel/lista-playas"
                :class="{ 'active': $route.path === '/panel/lista-playas', 'nonactive': $route.path !== '/panel/lista-playas' }">Lista
                de Playas</router-link>
            <router-link to="/panel/anadir-playa"
                :class="{ 'active': $route.path === '/panel/anadir-playa', 'nonactive': $route.path !== '/panel/anadir-playa' }">Añadir
                Playa</router-link>
            <router-link to="/panel/lista-comercios"
                :class="{ 'active': $route.path === '/panel/lista-comercios', 'nonactive': $route.path !== '/panel/lista-comercios' }">Lista
                de Comercios</router-link>
            <router-link to="/panel/anadir-comercio"
                :class="{ 'active': $route.path === '/panel/anadir-comercio', 'nonactive': $route.path !== '/panel/anadir-comercio' }">Añadir
                Comercio</router-link>
            <router-link to="/panel/lista-encuestas"
                :class="{ 'active': $route.path === '/panel/lista-encuestas', 'nonactive': $route.path !== '/panel/lista-encuestas' }">Lista
                de Encuestas</router-link>
            <router-link to="/panel/lista-avisos"
                :class="{ 'active': $route.path === '/panel/lista-avisos', 'nonactive': $route.path !== '/panel/lista-avisos' }">Lista
                de Avisos</router-link>
            <router-link to="/panel/mandar-notificacion-push"
                :class="{ 'active': $route.path === '/panel/mandar-notificacion-push', 'nonactive': $route.path !== '/panel/mandar-notificacion-push' }">Notificaciones
                Push</router-link>

            <div class="logout">
                <button v-on:click="logOut()" type="button" class="delete">CERRAR SESIÓN</button>
            </div>
        </div>
    </div>
    <!-- Menú tipo hamburguesa para dispositivos móviles -->
    <div class="navbar-burger" @click="toggleBurgerMenu">
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
    </div>

    <!-- Contenido del menú hamburguesa (se mostrará/ocultará según el estado) -->
    <div v-if="burgerMenuOpen" class="burger-menu">
        <div class="link" style="margin-top: 70px">
            <router-link @click="toggleBurgerMenu" to="/panel/lista-administradores"
                :class="{ 'active': $route.path === '/panel/lista-administradores', 'nonactive': $route.path !== '/panel/lista-administradores' }">Lista
                de Admins</router-link>
            <router-link @click="toggleBurgerMenu" to="/panel/lista-usuarios"
                :class="{ 'active': $route.path === '/panel/lista-usuarios', 'nonactive': $route.path !== '/panel/lista-usuarios' }">Lista
                de Usuarios</router-link>
            <router-link @click="toggleBurgerMenu" to="/panel/lista-playas"
                :class="{ 'active': $route.path === '/panel/lista-playas', 'nonactive': $route.path !== '/panel/lista-playas' }">Lista
                de Playas</router-link>
            <router-link @click="toggleBurgerMenu" to="/panel/anadir-playa"
                :class="{ 'active': $route.path === '/panel/anadir-playa', 'nonactive': $route.path !== '/panel/anadir-playa' }">Añadir
                Playa</router-link>
            <router-link @click="toggleBurgerMenu" to="/panel/lista-comercios"
                :class="{ 'active': $route.path === '/panel/lista-comercios', 'nonactive': $route.path !== '/panel/lista-comercios' }">Lista
                de Comercios</router-link>
            <router-link @click="toggleBurgerMenu" to="/panel/anadir-comercio"
                :class="{ 'active': $route.path === '/panel/anadir-comercio', 'nonactive': $route.path !== '/panel/anadir-comercio' }">Añadir
                Comercio</router-link>
            <router-link @click="toggleBurgerMenu" to="/panel/lista-encuestas"
                :class="{ 'active': $route.path === '/panel/lista-encuestas', 'nonactive': $route.path !== '/panel/lista-encuestas' }">Lista
                de Encuestas</router-link>
            <router-link @click="toggleBurgerMenu" to="/panel/lista-avisos"
                :class="{ 'active': $route.path === '/panel/lista-avisos', 'nonactive': $route.path !== '/panel/lista-avisos' }">Lista
                de Avisos</router-link>
            <router-link @click="toggleBurgerMenu" to="/panel/mandar-notificacion-push"
                :class="{ 'active': $route.path === '/panel/mandar-notificacion-push', 'nonactive': $route.path !== '/panel/mandar-notificacion-push' }">Notificaciones
                Push</router-link>
            <button v-on:click="logOut()" type="button" class="delete">CERRAR SESIÓN</button>
        </div>
    </div>
</template>

<script setup>
import axios from 'axios'
import { ref } from 'vue'
import router from '../routes';

let loading = ref(false)

const token = localStorage.getItem('famdifAdmin');
const headers = {
    Authorization: `Bearer ${token}`,
};

const burgerMenuOpen = ref(false);

const toggleBurgerMenu = () => {
    burgerMenuOpen.value = !burgerMenuOpen.value;
    console.log(burgerMenuOpen.value)
};

const logOut = async () => {
    loading.value = true
    try {
        await axios.post('/api/admin/logout', null, { headers });
        localStorage.removeItem('famdifAdmin');
        router.push('/login');
    } catch (error) {
        console.error('Error al cerrar sesión:', error);
    }
}

</script>

