<style scoped>
    .main-content-container{
        overflow: auto;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .loader{
        display: flex;
        width: 100%;
        height: 100vh;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .login-container {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .login-container, .loader {
        height: 100vh;
    }
    .logo {
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        border-radius: 4px;
        padding: 20px;
        margin: 20px 5px 40px 5px;
    }
    .logo img {
        max-width: 100%;
        height: auto;
        box-sizing: border-box;
    }

    .login-box {
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        background-color: #fff;
        padding: 20px;
        border-radius: 4px;
        margin: 5px;
    }

    form {
        display: flex;
        flex-direction: column;
        margin-top: 60px;
    }

    label {
        font-size: 16px;
        margin-bottom: 6px;
        display: block;
    }

    input {
        padding: 10px;
        margin-bottom: 12px;
        font-size: 14px;
        border: 1px solid #ccc;
        border-radius: 4px;
        display: block;
        width: 100%;
        box-sizing: border-box;
    }

    button {
        background-color: #2895D2;
        color: #fff;
        padding: 12px;
        font-size: 16px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        margin-top: 40px;
        transition: all 300ms;
    }

    button:hover {
        background-color: #E33222;
    }

    .error-box{
        width: 100%;
        padding: 20px;
        border-radius: 7px;
        background-color: #b91c1c;
        color: #fff;
        text-align: center;
        box-sizing: border-box;
        font-size: 20px;
    }
    @media screen and (max-width: 767px) {
        .login-container, .loader {
            height: auto;
        }
    }
</style>

<template>
    <div class="main-content-container">
        <div class="login-container">
            <div class="logo">
                <img src="/images/logofamdif.png" alt="">
            </div>
            <div class="login-box">
                <h1>{{ title }}</h1>
                <alert-component :show="showAlert" :message="alertMessage" @cerrar="cerrarAlerta" />
                <form @submit.prevent="sendForm">
                    <div>
                        <label for="email">Vuelva a Introducir su Correo</label>
                        <input type="email" id="email" v-model="data.formData.email" required>
                    </div>
                    <div class="form-group">
                        <label for="password">Nueva Contraseña</label>
                        <span style="font-size: 11px; font-style: italic">Un número, una mayúscula, una minúscula y 6+ caracteres</span>
                        <input
                          :type="showPassword ? 'text' : 'password'"
                          v-model="data.formData.password"
                          id="password"
                          required
                        />
                      </div>
                      <div class="form-group">
                        <label for="confirmPassword">Repetir Nueva Contraseña</label>
                        <input
                          :type="showPassword ? 'text' : 'password'"
                          v-model="confirmPassword"
                          id="confirmPassword"
                          required
                        />
                        <p v-if="passwordsDoNotMatch" class="error-box">Las contraseñas no coinciden.</p>
                      </div>
                      <button type="button" @click="togglePasswordVisibility">
                        {{ showPassword ? 'Ocultar Contraseñas' : 'Mostrar Contraseñas' }}
                      </button>
                      <button type="submit">Cambiar Contraseña</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { reactive, ref } from 'vue'
    import axios from 'axios'
    import { useRoute } from 'vue-router'
    import router from '../routes'
    import store from '../store'
    import AlertComponent from '../components/AlertsComponent.vue'

    let loading = ref(false)
    let showAlert = ref(false)
    let alertMessage = ref('')
    let showPassword = ref(false);

    const regexPassword = /^(?:(?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;

    const route = useRoute();
    const token = ref(route.params.token);

    let title = 'Nueva Contraseña'
    const data = reactive({
        formData: {
            email: '',
            password: '',
            token: token.value
        },
        error: {
            show: false,
            message: '',
        },
    });

    let confirmPassword = ref('')
    let passwordsDoNotMatch = ref(false)

    const togglePasswordVisibility = () => {
        showPassword.value = !showPassword.value;
    };

    const showAlerts = (message) => {
        showAlert.value = true
        alertMessage.value = message
    }

    const cerrarAlerta = () => {
        showAlert.value = false;
    }

    const sendForm = async () => {
        loading.value = true

        // Validar que las contraseñas coincidan
        if (data.formData.password !== confirmPassword.value) {
            showAlerts('Las contraseñas no coinciden.');
            loading.value = false
            return
        }

        // Validar la contraseña con la expresión regular
        if (!regexPassword.test(data.formData.password)) {
            showAlerts('La contraseña no cumple con los requisitos. Debe de tener al menos un número, una mayúscula, una minúscula y seis o más caracteres.');
            loading.value = false;
            return;
        }

        passwordsDoNotMatch.value = false

        try {
            const response = await axios.post(store.state.baseApi + 'reset-password', data.formData);

            if (response.data.error) {
                showAlerts(response.data.error);
                loading.value = false
            } else {
                showAlerts(response.data.success);
                loading.value = false
            }
        } catch (error) {
            loading.value = false
            showAlerts(error.message);
            console.error('Error al iniciar sesión', error);
        }
    };

</script>

