import { createRouter, createWebHistory } from 'vue-router'
import Login from './pages/Login.vue'
import ForgetPassword from './pages/ForgetPassword.vue'
import ForgetPasswordLink from './pages/ForgetPasswordLink.vue'
import Home from './pages/Home.vue'
import AddBeach from './pages/AddBeach.vue'
import BeachesList from './pages/BeachesList.vue'
import ShowBeach from './pages/ShowBeach.vue'
import AdminList from './pages/AdminList.vue'
import UsersList from './pages/UsersList.vue'
import ShowUser from './pages/ShowUser.vue'
import AddShop from './pages/AddShop.vue'
import ShopsList from './pages/ShopsList.vue'
import ShowShop from  './pages/ShowShop.vue'
import SurveyList from './pages/SurveysList.vue'
import NoticesList from './pages/NoticesList.vue'
import SendPush from './pages/SendPush.vue'


const routes = [
    { path: '/', requiresAuth: false, redirect: '/panel/inicio' },
    { path: '/login', requiresAuth: false, component: Login },
    { path: '/recuperar-contrasena', requiresAuth: false, component: ForgetPassword },
    { path: '/reset-password/:token', requiresAuth: false, component: ForgetPasswordLink },
    {
        path: '/panel',
        meta: { requiresAuth: true },
        component: () => import('./layouts/Panel.vue'),
        children: [
            { path: 'inicio', component: Home },
            { path: 'lista-administradores', component: AdminList },
            { path: 'lista-usuarios', component: UsersList },
            { path: 'detalle-usuario/:id', component: ShowUser, props: true},
            { path: 'anadir-playa', component: AddBeach },
            { path: 'lista-playas', component: BeachesList },
            { path: 'detalle-playa/:id', component: ShowBeach, props: true},
            { path: 'anadir-comercio', component: AddShop },
            { path: 'lista-comercios', component: ShopsList },
            { path: 'detalle-comercio/:id', component: ShowShop, props: true},
            { path: 'lista-encuestas', component: SurveyList },
            { path: 'lista-avisos', component: NoticesList },
            { path: 'mandar-notificacion-push', component: SendPush}
        ],
    },
    // Ruta de comodín para manejar rutas no registradas
    { path: '/:catchAll(.*)', redirect: '/panel/inicio' },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    let isAuthenticated = false;

    if(localStorage.getItem('famdifAdmin')){

        isAuthenticated = true;
    }

    // Verifica si la ruta requiere autenticación y si el usuario no está autenticado, redirige a /login
    if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
        next('/login');
    } else {
        next();
    }
});

export default router;
