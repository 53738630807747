<style scoped>
.main-content-container {
    overflow: auto;
    max-width: 100%;
}

.loader {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#map {
    height: 400px;
    width: 100%;
    margin-bottom: 25px;
}

.shop-list {
    display: flex;
    flex-direction: column;
    background-color: white;
    max-width: 100%;
    margin: 50px;
}

.go-back {
    width: fit-content;
    font-size: 14px;
    text-transform: uppercase;
    background-color: #E33222;
    margin: 30px 0px;
    transition: all 300ms;
}

.go-back:hover {
    background-color: #9b2419;
}

.shop-images {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    margin-bottom: 40px;
}

.image-container {
    position: relative;
    overflow: hidden;
    max-width: 100%;
}

img {
    width: 300px;
    height: 300px;
    object-fit: contain;
}

.delete-button {
    position: absolute;
    top: 15px;
    right: 10px;
    background-color: red;
    color: white;
    border: none;
    padding: 2px 8px 3px 9px;
    border-radius: 100%;
    cursor: pointer;
}

.add-image-button {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 40px 0px;
}

.shop-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.shop-card {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 4px;
    grid-column: span 1;
}

/* Estilos para el formulario */
.shop-form {
    width: 100%;
    margin: 0 auto;
}

/* Estilos para los grupos de formulario (label encima del input) */
.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.form-group label {
    font-weight: bold;
}

.form-group input, .form-group select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Estilos para el botón */
.button-container {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 50px 0px;
}

button {
    background-color: #2895D2;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 20px;
    transition: all 300ms;
}

button:hover {
    background-color: #E33222;
}

textarea {
    resize: vertical;
    padding: 10px;
    font-family: 'Montserrat', sans-serif;
    border: 1px solid #ccc;
    border-radius: 4px;

}

@media screen and (max-width: 1290px){
    .shop-images {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media screen and (max-width: 780px) {
    .shop-list{
        margin: 5px;
    }
    .shop-grid {
        display: flex;
        flex-direction: column;
    }
    .shop-images {
        grid-template-columns: repeat(1, 1fr);
    }
    img{
        width: 100%;
    }
}
@media screen and (max-width: 768px) {
    .main-content-container{
    margin-top: 80px;
}
}
</style>

<template>
    <div class="main-content-container">
        <div v-if="loadingMap" class="loader">
            <img src="/images/loader.svg" alt="Loader" />
        </div>

        <div v-if="!loading" class="shop-list">
            <h1>{{ title }} {{ shop.value.name }}</h1>
            <button v-on:click="goBack()" class="go-back">Volver al listado</button>
            <div id="map"></div>
            <form @submit="updateShop" class="shop-form">
                <div class="add-image-button">
                    <button @click.prevent="openFileSelector">Añadir Imagenes</button>
                    <input type="file" ref="fileInput" @change="handleFileChange" style="display: none" multiple>
                </div>
                <div v-if="shop.value.images" class="shop-images">
                    <div v-for="img in shop.value.images" :key="img.id" class="image-container">
                        <img :src="img" alt="">
                        <button @click="removeImage(img)" class="delete-button">x</button>
                    </div>
                </div>

                <div class="shop-grid">
                    <div class="shop-card">
                        <div class="form-group">
                            <label for="file_number">Nº de Ficha</label>
                            <input type="text" id="file_number" v-model="shop.value.file_number" />
                        </div>
                        <div class="form-group">
                            <label for="external_sorting">Clasificación Ext.</label>
                            <input type="text" id="external_sorting" v-model="shop.value.external_sorting" />
                        </div>
                    </div>
                    <div class="shop-card">
                        <div class="form-group">
                            <label for="long">Latitud</label>
                            <input type="text" id="long" v-model="shop.value.lat" />
                        </div>
                        <div class="form-group">
                            <label for="lat">Longitud</label>
                            <input type="text" id="lat" v-model="shop.value.long" />
                        </div>
                    </div>
                    <div class="shop-card">
                        <div class="form-group">
                            <label for="name">Nombre Comercial</label>
                            <input type="text" id="name" v-model="shop.value.name" />
                        </div>
                        <div class="form-group">
                            <label for="activity">Uso</label>
                            <select v-model="shop.value.use">
                                <option v-for="use in uses.value" :value="use">{{ use }}</option>
                            </select>
                        </div>

                        <div class="form-group">
                            <label for="activity">Actividad</label>
                            <input type="text" id="activity" v-model="shop.value.activity" />
                        </div>
                    </div>
                    <div class="shop-card">
                        <div class="form-group">
                            <label for="address">Dirección</label>
                            <input type="text" id="address" v-model="shop.value.address" />
                        </div>
                        <div class="form-group">
                            <label for="long">Ciudad</label>
                            <input type="text" id="location" v-model="shop.value.location" />
                        </div>
                        <div class="form-group">
                            <label for="lat">Barrio</label>
                            <input type="text" id="district" v-model="shop.value.district" />
                        </div>
                    </div>
                    <div class="shop-card">
                        <div class="form-group">
                            <label for="address">Terraza</label>
                            <textarea rows="3" id="terrace" v-model="shop.value.terrace" />
                        </div>
                        <div class="form-group">
                            <label for="long">Interior</label>
                            <textarea rows="3" id="interior" v-model="shop.value.interior" />
                        </div>
                        <div class="form-group">
                            <label for="lat">Aseos</label>
                            <textarea rows="3" id="baths" v-model="shop.value.baths" />
                        </div>
                    </div>

                    <div class="shop-card">
                        <div class="form-group">
                            <label for="access">Acceso</label>
                            <textarea rows="3" id="access" v-model="shop.value.access" />
                        </div>
                        <div class="form-group">
                            <label for="access_door">Puerta de Acceso</label>
                            <textarea rows="3" id="access_door" v-model="shop.value.access_door" />
                        </div>
                    </div>
                </div>
                <div class="button-container">
                    <button type="submit">Guardar Cambios</button>
                </div>
            </form>
        </div>
    </div>
</template>


<script setup>
import axios from 'axios'
import { ref, toRefs, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import router from '../routes'
import AlertComponent from '../components/AlertsComponent.vue'

let loading = ref(true)
let loadingMap = ref(true)

const route = useRoute();
const id = ref(route.params.id);

const fileInput = ref(null);

const token = localStorage.getItem('famdifAdmin');
const headers = {
    Authorization: `Bearer ${token}`,
};

const title = 'Detalle del Comercio '
const shop = [];
const uses = toRefs(null)

const goBack = () => {
    router.push('/panel/lista-comercios')
}

onMounted(() => {
    setTimeout(() => {
        showMap();
    }, 1500);
});

const fetchShop = () => {
    loading.value = true
    axios.get('/api/admin/show-shop/' + id.value, {
        headers,
    })
        .then(res => {
            uses.value = res.data.uses
            shop.value = res.data.shop;
            loading.value = false
        })
        .catch(error => {
            loading.value = false
            console.error('Error al obtener la playa:', error);
        });
}

const showMap = () => {
    const lat = shop.value.lat.replace(',', '.')
    const long = shop.value.long.replace(',', '.')
    var map = L.map('map').setView([lat, long], 17);
    var marker = L.marker([lat, long]).addTo(map);
    loadingMap.value = false
    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
    }).addTo(map).addTo(marker);
}


const removeImage = (image) => {
    loading.value = true

    const formData = new FormData();
    formData.append('id', id.value);
    formData.append('image', image);

    axios.post('/api/admin/delete-image-shop', formData, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then(res => {
            shop.value = res.data.shop
            loading.value = false;
        })
        .catch(error => {
            loading.value = false;
            console.error('Error al guardar imágenes:', error);
        });
}

const openFileSelector = () => {
    // Abre el selector de archivos cuando se hace clic en el botón "Añadir Imagen"
    fileInput.value.click();
}

const handleFileChange = (event) => {
    // Maneja el cambio en el selector de archivos
    const files = event.target.files;
    // Implementa la lógica para procesar las nuevas imágenes
    console.log('Nuevas imágenes seleccionadas:', files);
    // Llama a la función saveNewImages() con las imágenes seleccionadas
    saveNewImages(files);
}

const saveNewImages = (files) => {
    loading.value = true

    const formData = new FormData();
    formData.append('id', id.value);
    for (let i = 0; i < files.length; i++) {
        formData.append(`images[${i}]`, files[i]);
    }

    axios.post('/api/admin/save-images-shop', formData, {
        headers: {
            'Content-Type': 'multipart/form-data', // Necesario para enviar archivos
            Authorization: `Bearer ${token}`,
        },
    })
        .then(res => {
            shop.value = res.data.shop
            loading.value = false;
        })
        .catch(error => {
            loading.value = false;
            console.error('Error al guardar imágenes:', error);
        });
}

const updateShop = () => {
    loading.value = true

    const formData = new FormData();

    for (const [key, value] of Object.entries(shop.value)) {
        // Excluir campos específicos
        if (key !== 'images' && key !== 'created_at' && key !== 'updated_at' && value !== null && value !== undefined) {
            formData.append(key, value)
        }
    }

    // Realizar la petición POST con formData
    axios.post('/api/admin/edit-shop', formData, {
        headers: headers,
    })
        .then(response => {
            fetchShop()
            console.log('Comercio actualizada con éxito:', response.data);
        })
        .catch(error => {
            loading.value = false
            // Manejar errores de la llamada al servidor
            console.error('Error al actualizar comercio:', error);
        });
}

fetchShop();

</script>
