<style scoped>
.main-content-container{
    overflow: auto;
    max-width: 100%;
}
.loader {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.search-bar {
    margin-bottom: 20px;
    position: relative;
}

.search-bar input {
    width: calc(100% - 30px);
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 16px;
}

.clear-button {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    background-color:#E33222;
    color: #fff;
    padding: 3px 5px;
    border-radius: 7px;
}

.shops-list {
    display: flex;
    flex-direction: column;
    background-color: white;
    max-width: 100%;
    margin: 50px;
}

.fixed-table {
    table-layout: auto;
}

.shops-table {
    margin-top: 40px;
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
}

.shops-table th,
.shops-table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
    width: auto; /* O podrías probar con porcentajes, por ejemplo, width: 10%; */
        white-space: normal;
        overflow: hidden; /* Ocultar cualquier desbordamiento */
        text-overflow: ellipsis; /* Agregar puntos suspensivos para indicar desbordamiento */
}
.buttons-column {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-self: stretch;
    justify-content: center;
    align-items: center;
}
.shops-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

.shops-table tbody tr:hover {
    background-color: #e0e0e0;
}
.details {
    cursor: pointer;
    background-color: #2895D2;
    color: #fff;
    padding: 4px 8px;
    border: none;
    border-radius: 4px;
    transition: all 300ms;
    font-size: 14px;
    white-space: normal;
    margin-bottom: 5px;
}

.details:hover {
    background-color: #144663;
}

.delete {
    cursor: pointer;
    background-color: #E33222;
    color: #fff;
    padding: 4px 8px;
    border: none;
    border-radius: 4px;
    transition: all 300ms;
    font-size: 14px;
    white-space: normal;
}

.delete:hover {
    background-color: #811e15;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
}

.pagination button {
    margin: 0px 5px;
}

.page-numbers {
    display: flex;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
}

.pagination button,
.page-numbers button {
    background-color: #fff;
    color: #333;
    border: 1px solid #ccc;
    padding: 5px 10px;
    cursor: pointer;
}

.page-numbers button.active {
    background-color: #F3A225;
    color: #fff;
    border: 1px solid #F3A225;
}
@media screen and (max-width: 780px) {
    .shops-list{
        margin: 5px;
    }
}
@media screen and (max-width: 768px) {
    .main-content-container{
        margin-top: 80px;
    }
    .shops-table thead,
    .shops-table tbody,
    .shops-table th,
    .shops-table td,
    .shops-table tr {
        display: block;
    }

    .shops-table th,
    .shops-table td {
        text-align: left;
    }

    .shops-table th {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .shops-table tr {
        margin-bottom: 15px;
    }

    .shops-table td {
        border: none;
        border-bottom: 1px solid #ccc;
        position: relative;
        padding-left: 50%;
    }

    .shops-table td:before {
        position: absolute;
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        content: attr(data-label);
        font-weight: bold;
    }

    .shops-table td:last-child {
        border-bottom: none;
        padding-left: 0; /* Evitar que el último elemento tenga espacio a la izquierda */
    }
}
</style>

<template>
    <div class="main-content-container">
        <div v-if="loading" class="loader">
            <img src="/images/loader.svg" alt="Loader" />
        </div>
        <div v-if="!loading" class="shops-list">
            <AlertsConfirmationComponent :show="showDeleteAlert" :message="deleteAlertMessage" @accept="confirmDeleteElement" @cancel="cancelDeleteElement" />
            <h1>{{ title }}</h1>

            <!-- Buscador -->
            <div class="search-bar">
                <input v-model="keySearch" @keyup.enter="searchShops" placeholder="Buscar comercios..." />
                <button v-if="keySearch" @click="clearSearch" class="clear-button">X</button>
            </div>

            <table class="shops-table fixed-table">
                <thead>
                    <tr>
                        <th @click="sort('file_number')" style="cursor: pointer">Nº de Ficha</th>
                        <th @click="sort('name')" style="cursor: pointer">Razón Social</th>
                        <th @click="sort('use')" style="cursor: pointer">Uso</th>
                        <th @click="sort('address')" style="cursor: pointer">Dirección</th>
                        <th @click="sort('location')" style="cursor: pointer">Ciudad</th>
                        <th @click="sort('external_sorting')" style="cursor: pointer">Clasificación Ext.</th>
                        <th @click="sort('created_at')" style="cursor: pointer">Creada</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="shop in shops.value" :key="shop.id">
                        <td data-label="Nº de Ficha">{{ shop.file_number }}</td>
                        <td data-label="Nombre">{{ shop.name }}</td>
                        <td data-label="Uso">{{ shop.use }}</td>
                        <td data-label="Dirección">{{ shop.address }}</td>
                        <td data-label="Ciudad">{{ shop.location }}</td>
                        <td data-label="Clasificación Ext.">{{ shop.external_sorting }}</td>
                        <td data-label="Creada el">{{ formatDateTime(shop.created_at) }}</td>
                        <td data-label="">
                            <div class="buttons-column">
                                <button v-on:click="showDetails(shop.id)" type="button" class="details">EDITAR</button>
                                <button v-on:click="deleteBeach(shop.id)" type="button" class="delete">ELIMINAR</button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <!-- Paginación -->
            <div class="pagination">
                <button @click="changePage(currentPage - 1)" :disabled="currentPage === 1">Anterior</button>
                <div class="page-numbers">
                    <template v-for="page in displayedPages" v-if="page === '...'">
                        <span>{{ page }}</span>
                    </template>
                    <button v-for="page in displayedPages" :key="page" @click="changePage(page)"
                        :class="{ active: page === currentPage }">{{ page }}</button>
                </div>
                <button @click="changePage(currentPage + 1)"
                    :disabled="currentPage === Math.ceil(totalItems / perPage)">Siguiente</button>
            </div>
        </div>
    </div>
</template>


<script setup>
import axios from 'axios'
import { ref } from 'vue'
import router from '../routes';
import AlertComponent from '../components/AlertsComponent.vue'
import AlertsConfirmationComponent from '../components/AlertsConfirmationComponent.vue';

let loading = ref(true)
let showDeleteAlert = ref(false)
let deleteAlertMessage = ref('')
let deleteElementToDelete = ref(null)

let currentPage = ref(1)
let perPage = ref(10)
let totalItems = ref(0)
let order_by = ref('created_at')
let order = ref('DESC')
let keySearch = ref('');

const displayedPages = ref([]);

const token = localStorage.getItem('famdifAdmin');
const headers = {
    Authorization: `Bearer ${token}`,
};

const title = 'Listado de Comercios'
const shops = [];

// Método para mostrar la alerta de confirmación antes de eliminar al usuario
const deleteElement = (id) => {
    // Configura el mensaje de la alerta y muestra la alerta
    deleteAlertMessage.value = '¿Estás seguro de que deseas eliminar este usuario?'
    showDeleteAlert.value = true
    // Guarda el ID del usuario a eliminar para usarlo más tarde
    deleteElementToDelete.value = id
}

// Método para confirmar la eliminación del usuario
const confirmDeleteElement = () => {
    // Oculta la alerta
    showDeleteAlert.value = false
    // Ejecuta la eliminación del usuario
    if (deleteElementToDelete.value) {
        deleteElementRequest(deleteElementToDelete.value)
    }
}

// Método para cancelar la eliminación del usuario
const cancelDeleteElement = () => {
    // Oculta la alerta y restablece el ID del usuario a eliminar
    showDeleteAlert.value = false
    deleteElementToDelete.value = null
}

// Método para realizar la solicitud de eliminación del usuario
const deleteElementRequest = (id) => {
    loading.value = true
    axios.delete('/api/admin/delete-shop/' + id, {
        headers,
    })
        .then(response => {
            if (response.data.error) {
                loading.value = false
                if (response.data.error.message) {
                    showAlerts(response.data.error.message)
                } else {
                    showAlerts(response.data.error)
                }
            } else {
                fetchShops()
                showAlerts(response.data.success)
            }
        })
        .catch(error => {
            loading.value = false
            console.error('Error al obtener la lista de playas:', error);
        });
}

const formatDateTime = (dateTime) => {
    if (dateTime) {
        const date = new Date(dateTime);
        return date.toLocaleDateString();
    }
    return '';
}

const changePage = (page) => {
    currentPage.value = page;
    fetchShops();
}

const fetchShops = () => {
    loading.value = true
    axios.get('/api/admin/shops-list', {
        headers,
        params: {
            page: currentPage.value,
            per_page: perPage.value,
            order_by: order_by.value,
            order: order.value,
            keySearch: keySearch.value
        }
    })
        .then(res => {
            shops.value = res.data.shops.data;
            totalItems.value = res.data.shops.total;
            updateDisplayedPages();
            loading.value = false
        })
        .catch(error => {
            loading.value = false
            console.error('Error al obtener la lista de playas:', error);
        });
}

const updateDisplayedPages = () => {
    const totalPages = Math.ceil(totalItems.value / perPage.value);
    const maxDisplayedPages = 10;

    let startPage = Math.max(1, currentPage.value - Math.floor(maxDisplayedPages / 2));
    let endPage = Math.min(startPage + maxDisplayedPages - 1, totalPages);

    if (endPage - startPage + 1 < maxDisplayedPages) {
        startPage = Math.max(1, endPage - maxDisplayedPages + 1);
    }

    displayedPages.value = [];

    if (startPage > 1) {
        displayedPages.value.push(1);
        if (startPage > 2) {
            displayedPages.value.push('...');
        }
    }

    for (let i = startPage; i <= endPage; i++) {
        displayedPages.value.push(i);
    }

    if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
            displayedPages.value.push('...');
        }
        displayedPages.value.push(totalPages);
    }
}

const searchShops = () => {
    currentPage.value = 1;
    fetchShops();
}

const clearSearch = () => {
  keySearch.value = '';
  searchShops();
}

const sort = (field) => {
    if (field === order_by.value) {
        order.value = order.value === 'ASC' ? 'DESC' : 'ASC';
    } else {
        order_by.value = field;
        order.value = 'ASC';
    }
    fetchShops();
}

const showDetails = (id) => {
    router.push('/panel/detalle-comercio/' + id);
}

fetchShops();

</script>
