require('./bootstrap');

import { createApp } from 'vue';
import router from './routes.js';
import store from './store.js';

import App from './layouts/App.vue'
import Panel from './layouts/Panel.vue'
import NavBar from './layouts/Navbar.vue'
import Content from './layouts/Content.vue'

import Login from './pages/Login.vue'
import ForgetPassword from './pages/ForgetPassword.vue'
import ForgetPasswordLink from './pages/ForgetPasswordLink.vue'
import Home from './pages/Home.vue'
import AddBeach from './pages/AddBeach.vue'
import BeachesList from './pages/BeachesList.vue'
import ShowBeach from './pages/ShowBeach.vue'
import AdminList from './pages/AdminList.vue'
import UsersList from './pages/UsersList.vue'
import ShowUser from './pages/ShowUser.vue'
import AddShop from './pages/AddShop.vue'
import ShopsList from './pages/ShopsList.vue'
import ShowShop from  './pages/ShowShop.vue'
import SurveyList from './pages/SurveysList.vue'
import NoticesList from './pages/NoticesList.vue'
import SendPush from './pages/SendPush.vue'

import { EmojiPickerPlugin } from 'vue-emoji-picker'

// Vuetify
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const app = createApp({})

const vuetify = createVuetify({
    components,
    directives
})

// Configurar Axios para que use la base URL de tu API
axios.defaults.baseURL = store.state.baseUrl;

// Configurar Axios para que use la URL almacenada en tu store Vuex
axios.interceptors.request.use(config => {
    const apiUrl = store.state.apiUrl; // Suponiendo que tienes una propiedad llamada apiUrl en tu store
    if (apiUrl) {
      config.baseURL = apiUrl;
    }
    return config;
  });

  // Interceptar todas las respuestas HTTP
  axios.interceptors.response.use(
    response => response, // Si la respuesta es exitosa, simplemente devuélvela sin cambios
    error => {
      // Si la respuesta es un error (por ejemplo, 401 Unauthorized), manejarlo aquí
      if (error.response.status === 401) {
        // Redirigir al usuario a la página de inicio de sesión
        window.location.href = '/login';
      }
      // Propagar el error para que los componentes Vue3 también puedan manejarlo si es necesario
      return Promise.reject(error);
    }
  );

//Layouts
app.component('App', App)
app.component('Panel', Panel)
app.component('Navbar', NavBar)
app.component('Content', Content)

//Pages
app.component('Login', Login)
app.component('ForgetPassword', ForgetPassword)
app.component('ForgetPasswordLink', ForgetPasswordLink)

app.component('Home', Home)
app.component('AddBeach', AddBeach)
app.component('BeachesList', BeachesList)
app.component('ShowBeach', ShowBeach)
app.component('AdminList', AdminList)
app.component('UsersList', UsersList)
app.component('ShowUser', ShowUser)
app.component('AddShop', AddShop)
app.component('ShopsList', ShopsList)
app.component('ShowShop', ShowShop)
app.component('SurveyList', SurveyList)
app.component('NocitesList', NoticesList)
app.component('SendPush', SendPush)

app.use(store)
app.use(router)
app.config.devtools = true
app.use(vuetify).mount('#app')
