<style scoped>
.content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.scroll-container {
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 10px;
}

.scroll-container table {
    width: 100%;
    table-layout: fixed; /* Fuerza a la tabla a utilizar el ancho especificado */
    word-wrap: break-word; /* Rompe las palabras largas */
}

.scroll-container th, .scroll-container td {
    white-space: nowrap; /* Evita el desbordamiento de texto en celdas largas */
    overflow: hidden;
    text-overflow: ellipsis; /* Muestra puntos suspensivos (...) para el texto que desborda */
}
</style>

<template>
    <div class="content">
        <div class="scroll-container">
            <router-view></router-view>
        </div>
    </div>
</template>

